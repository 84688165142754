import React, { FC } from 'react';

import { Table, Typography } from 'antd';

import { IService } from '../../../../store/schedule';
import { isMobile } from 'react-device-detect';

export const servicesColumns = [
  {
    title: 'Назва',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Ціна, грн',
    dataIndex: 'price',
    key: 'price',
    width: isMobile ? '50px' : '15%',
  },
];

interface ServicesTableProps {
  title: string;
  placeholder: string;
  services: IService[];
  onRow: (service: IService) => { onClick: () => void };
  rowClassName?: (row: IService) => string;
  loading?: boolean;
  className?: string;
}

const ServicesTable: FC<ServicesTableProps> = ({
  title,
  services,
  loading,
  placeholder,
  onRow,
  rowClassName,
  className,
}) => {
  return (
    <>
      <Typography style={{ fontSize: 16, marginTop: 10 }}>{title}</Typography>
      <Table
        className={className}
        rowClassName={rowClassName}
        style={{ margin: '10px 0 0 5px' }}
        columns={servicesColumns}
        scroll={{ y: 210 }}
        loading={loading}
        pagination={false}
        locale={{ emptyText: placeholder }}
        size="small"
        dataSource={services.map((s) => ({
          ...s,
          key: s.serviceUUID,
        }))}
        onRow={onRow}
      />
    </>
  );
};

export default ServicesTable;
