export enum LoadingStatus {
  NEVER = 'NEVER',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface IPatient {
  date: string;
  patientID: number;
  patientName: string;
  patientPhone: string;
  patientBirthDate: string;
  studyUUID: string;
  study: string;
  downloads: IStudyDownload[];
}

export interface IStudyDownload {
  type: string;
  fileUUID: string;
}