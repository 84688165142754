import React, { FC } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/uk';

import { TimePicker } from 'antd';
import { timeFormat } from '../../constants';

moment.locale('uk');

interface TimeProps {
  setTime: (time: string) => void;
}

const Time: FC<TimeProps> = ({ setTime }) => {
  const handleChange = (value: Moment | null) => {
    value && setTime(value.format(timeFormat));
  };

  return (
    <div>
      <TimePicker
        style={{ marginBottom: 20 }}
        onChange={handleChange}
        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24]}
        hideDisabledOptions
        format={timeFormat}
        minuteStep={5}
        inputReadOnly
        showNow={false}
      />
    </div>
  );
};

export default Time;
