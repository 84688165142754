import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';

import { Alert, Button, Collapse, DatePicker, Input, notification } from 'antd';

import useStore from '../../hooks/useStore';
import { LoadingStatus } from '../../store/types';
import { ScheduleApi } from '../../api/schedule';
import { SearchData } from './types';
import { dateFormat } from './constants';
import { Services } from './components';
import { MASKED } from './components/CalendarDate';

interface CreateOfferProps {
  studyUUID: string;
  handleSuccess: () => void;
}

const { Panel } = Collapse;

const ReOffer: FC<CreateOfferProps> = ({ studyUUID, handleSuccess }) => {
  const { scheduleStore } = useStore();

  const [searchData, setSearchData] = useState<SearchData>({
    date: moment().format(dateFormat),
    patient: {
      patientID: 23,
    },
    services: [],
    notes: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      const data = await ScheduleApi.getUserStudy(studyUUID);
      setSearchData({
        date: moment(new Date()).add(1, 'd').format(dateFormat),
        notes: data.notes,
        patient: { patientID: data.patientID },
        services: data.services,
      });
    })();
  }, [studyUUID]);

  useEffect(() => {
    if (scheduleStore.offerLoadingStatus === LoadingStatus.SUCCESS) {
      notification.success({
        message: 'Запис додано',
      });
      scheduleStore.setOfferLoadingStatus(LoadingStatus.NEVER);
      handleSuccess();
    } else if (scheduleStore.offerLoadingStatus === LoadingStatus.ERROR) {
      notification.error({
        message: scheduleStore.offerError || 'Виникла помилка',
      });
      scheduleStore.setOfferLoadingStatus(LoadingStatus.NEVER);
    }
  }, [scheduleStore.offerLoadingStatus, handleSuccess]);

  const handleCreate = () => {
    (async () => {
      if (!searchData.services?.length) {
        setError('Послуги не обрані');
      } else if (!searchData.date) {
        setError('Дата не обрана');
      } else {
        setError('');
        await scheduleStore.offer(searchData);
      }
    })();
  };

  return (
    <div>
      <Collapse
        defaultActiveKey={[
          'patient',
          'time',
          'services',
          'notes',
          'serviceType',
          'date',
        ]}
      >
        <Panel header="Дата" key="date">
          <DatePicker
            format={dateFormat}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              const input = event.target as HTMLInputElement;
              input.value = MASKED.resolve(input.value);
            }}
            style={{ marginBottom: 20 }}
            onChange={(value) =>
              value &&
              setSearchData((prev) => ({
                ...prev,
                date: value.format(dateFormat),
              }))
            }
            value={moment(new Date())}
            allowClear={false}
          />
        </Panel>
        <Panel header="Обрати послуги" key="services">
          <Services
            date={searchData.date}
            value={searchData.services}
            removeService={(service) => {
              setSearchData((prev) => ({
                ...prev,
                services: (prev.services || []).filter(
                  (s: any) => s.serviceUUID !== service.serviceUUID
                ),
              }));
            }}
            addService={(service) =>
              setSearchData((prev) => ({
                ...prev,
                services: [...prev.services, service],
              }))
            }
          />
        </Panel>
        <Panel header="Примітка" key="notes">
          <Input
            placeholder="Примітка"
            defaultValue={searchData.notes}
            onBlur={(e) =>
              setSearchData((prev) => ({ ...prev, notes: e.target.value }))
            }
          />
        </Panel>
      </Collapse>
      {!!error && (
        <Alert style={{ marginTop: 24 }} message={error} type="error" />
      )}
      <Button
        style={{ margin: '20px 0' }}
        type="primary"
        loading={scheduleStore.offerLoadingStatus === LoadingStatus.LOADING}
        onClick={handleCreate}
      >
        Створити запис
      </Button>
    </div>
  );
};

export default observer(ReOffer);
