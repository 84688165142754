import React, { FC } from 'react';

import { DatePicker, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';

import styles from '../../offer.module.scss';

import { PatientSearchData } from '../../types';
import { dateFormat } from '../../constants';
import { MASKED } from '../CalendarDate';

interface PatientFormProps {
  onSubmit: (values: Omit<PatientSearchData, 'patientID'>) => void;
}

const DATE_FORMAT = dateFormat;

const PatientFormForOffer: FC<PatientFormProps> = ({ onSubmit }) => {
  const [form] = useForm();

  const handleForm = () => {
    const { name, email, phone, birthDate } = form.getFieldsValue();
    onSubmit({
      name,
      phone,
      email,
      birthDate: birthDate ? birthDate.format(dateFormat) : '',
    });
  };

  return (
    <Form
      className={styles.patientForm}
      form={form}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        birthDate: undefined,
      }}
      onBlur={handleForm}
      onFinish={handleForm}
    >
      <Form.Item label="Повне ім'я" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="E-mail" name="email">
        <Input />
      </Form.Item>
      <Form.Item label="Номер телефону" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="Дата народження" name="birthDate">
        <DatePicker
          format={DATE_FORMAT}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement;
            input.value = MASKED.resolve(input.value);
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default PatientFormForOffer;
