export const extractQueryParam = (url: string, param: string) => {
  return new URLSearchParams(url).get(param);
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  const elem = window.document.createElement('a');
  elem.href = fileUrl;
  elem.download = fileName;
  elem.click();
};

export const toInitials = (name: string) => {
  const [lastName, firstName, middleName] = name.split(' ');
  let initials = lastName;
  if (firstName) {
    initials += ` ${firstName[0]}.`
  }
  if (middleName) {
    initials += ` ${middleName[0]}.`
  }
  return initials
};

export const ddmmyyyyToDate = (dateString: string): Date => {
  const dateParts = dateString.split('-');

  return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
};

export const createFullname = ({
  firstname,
  middlename,
  lastname,
}: {
  firstname: string;
  middlename: string;
  lastname: string;
}) => {
  return `${lastname} ${firstname} ${middlename}`;
};
