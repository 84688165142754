import moment from 'moment';
import { baseurl } from './baseurl';
import { Study } from './types';
import { IService, IServiceType, IStudyRoom } from '../store/schedule';
import makeRequest from './makeRequest';
import { NewOfferSearchData, SearchData } from '../modules/offer/types';
import { dateFormat } from '../modules/offer/constants';

export const ScheduleApi = {
  getServiceById: async (id: string): Promise<IService> => {
    const { data } = await makeRequest<IService>({
      url:
        baseurl +
        `/service/${id}/${moment(new Date()).add(1, 'd').format(dateFormat)}`,
    });
    return data;
  },
  getUserStudy: async (studyUUID: string): Promise<Study> => {
    const { data } = await makeRequest<Study>({
      url: baseurl + `/studies/${studyUUID}`,
    });

    if (data.services.length) {
      const services = await Promise.all(
        data.services.map((service) =>
          ScheduleApi.getServiceById(service.serviceUUID)
        )
      );
      return { ...data, services };
    }

    return data;
  },
  getStudyRooms: async () => {
    const { data } = await makeRequest<IStudyRoom>({
      url: baseurl + '/study-rooms',
    });
    return data;
  },
  getServiceTypes: async () => {
    const { data } = await makeRequest<IServiceType>({
      url: baseurl + '/service-types',
    });
    return data;
  },
  getServices: async (serviceTypeUUID: string, date: string) => {
    const { data } = await makeRequest<IService[]>({
      url: baseurl + `/services/${serviceTypeUUID}/${date}`,
    });
    return data;
  },
  offer: async (payload: SearchData | NewOfferSearchData) => {
    await makeRequest({
      method: 'post',
      url: baseurl + `/schedule/offer`,
      data: payload,
    });
  },
};
