import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Input, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

import styles from './SearchPatient.module.scss';

import useSearchPatients from './useSearchPatients';
import CreateNewOffer from '../offer/CreateNewOffer';
import { Patients } from './components';
import useStore from '../../hooks/useStore';
import { LoadingStatus } from '../../store/types';

const SearchPatient: FC = () => {
  const { patientsStore } = useStore();

  const { searchValue, setSearchValue, searchedPatients } = useSearchPatients();
  const [offerModal, setOfferModal] = useState(false);

  const jsonDoctorName = localStorage.getItem('name');
  const doctorName = jsonDoctorName ? JSON.parse(jsonDoctorName) : '';

  return (
    <>
      <div className={styles.searchPatient}>
        <Title className={styles.title} level={4}>
          Кабінет лікаря: {doctorName}
        </Title>
        <Input
          autoFocus
          className={styles.input}
          prefix={<SearchOutlined />}
          placeholder="Введіть ПІП або номер телефону пацієнта"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button
          className={styles.orderLink}
          type="primary"
          onClick={() => setOfferModal(true)}
        >
          Попередній запис
        </Button>
      </div>
      <Patients
        patients={searchedPatients}
        loading={
          patientsStore.getPatientsLoadingStatus === LoadingStatus.LOADING
        }
        error={patientsStore.getPatientError}
      />

      <Modal
        title="Попередній запис"
        visible={offerModal}
        width={1000}
        footer={false}
        onCancel={() => setOfferModal(false)}
      >
        <CreateNewOffer onCreated={() => setOfferModal(false)} />
      </Modal>
    </>
  );
};

export default observer(SearchPatient);
