import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { SearchPatient } from '../../modules';
import { DCInfo, Nav } from '../../components';

const Patients: FC = () => {
  return (
    <>
      <DCInfo />
      <Nav />
      <SearchPatient />
    </>
  );
};

export default observer(Patients);
