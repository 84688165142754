import { types } from 'mobx-state-tree';

import CashFiles from './cashFiles';
import ScheduleStore from './schedule';
import PatientStore from './patient';
import { LoadingStatus } from './types';

const RootStore = types.model('RootStore', {
  patientsStore: types.optional(PatientStore, {}),
  cashFiles: types.optional(CashFiles, {}),
  scheduleStore: types.optional(ScheduleStore, {
    getStudyRoomsLoadingStatus: LoadingStatus.NEVER,
    getServiceTypesLoadingStatus: LoadingStatus.NEVER,
    offerLoadingStatus: LoadingStatus.NEVER,
  }),
});

export default RootStore;
