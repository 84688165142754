import React, { FC } from 'react';
import moment from 'moment';
import IMask from 'imask';
import 'moment/locale/uk';

import { DatePicker } from 'antd';

import { dateFormat } from '../../constants';

moment.locale('uk');

const DATE_FORMAT = dateFormat;
export const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

interface CalendarDateProps {
  setDate: (date: string) => void;
  defaultDate?: string;
}

const CalendarDate: FC<CalendarDateProps> = ({ setDate, defaultDate }) => {
  return (
    <div>
      <DatePicker
        format={dateFormat}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          const input = event.target as HTMLInputElement;
          input.value = MASKED.resolve(input.value);
        }}
        style={{ marginBottom: 20 }}
        defaultValue={moment(defaultDate || undefined)}
        disabledDate={(current) => current < moment().subtract(1, 'day')}
        allowClear={false}
        onChange={(value) => value && setDate(value.format(dateFormat))}
      />
    </div>
  );
};

export default CalendarDate;
