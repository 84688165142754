import { flow, Instance, types } from 'mobx-state-tree';

import { LoadingStatus } from './types';
import { ScheduleApi } from '../api/schedule';
import { NewOfferSearchData, SearchData } from '../modules/offer/types';

const StudyRoom = types.model('StudyRoom', {
  studyRoomUUID: types.string,
  name: types.string,
});

export interface IStudyRoom extends Instance<typeof StudyRoom> {}

const ServiceType = types.model('ServiceType', {
  serviceTypeUUID: types.string,
  name: types.string,
});

export interface IServiceType extends Instance<typeof ServiceType> {}

const Service = types.model('Service', {
  serviceUUID: types.string,
  name: types.string,
  price: types.number,
});

export interface IService extends Instance<typeof Service> {}

const ScheduleStore = types
  .model('ScheduleStore', {
    serviceTypes: types.array(ServiceType),
    studyRooms: types.array(StudyRoom),
    getStudyRoomsLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    getServiceTypesLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    offerLoadingStatus: types.enumeration<LoadingStatus>(
      Object.values(LoadingStatus)
    ),
    offerError: types.optional(types.string, ''),
    unauthorizedError: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setGetStudyRoomsLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.getStudyRoomsLoadingStatus = loadingStatus;
    };

    const setServiceTypesLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.getServiceTypesLoadingStatus = loadingStatus;
    };

    const setOfferLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.offerLoadingStatus = loadingStatus;
    };

    const getStudyRooms = flow(function* () {
      try {
        setGetStudyRoomsLoadingStatus(LoadingStatus.LOADING);
        self.studyRooms = yield ScheduleApi.getStudyRooms();
        setGetStudyRoomsLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        setGetStudyRoomsLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const getServiceTypes = flow(function* () {
      try {
        setServiceTypesLoadingStatus(LoadingStatus.LOADING);
        self.serviceTypes = yield ScheduleApi.getServiceTypes();
        setServiceTypesLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        setServiceTypesLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const offer = flow(function* (payload: SearchData | NewOfferSearchData) {
      try {
        self.offerError = '';
        setOfferLoadingStatus(LoadingStatus.LOADING);
        yield ScheduleApi.offer(payload);
        setOfferLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        console.dir(e)
        self.offerError = e.message;
        setOfferLoadingStatus(LoadingStatus.ERROR);
      }
    });

    return {
      getStudyRooms,
      getServiceTypes,
      offer,
      setOfferLoadingStatus,
    };
  });

export default ScheduleStore;
