import { v4 as uuidv4 } from 'uuid';

import { baseurl } from './baseurl';
import makeRequest from './makeRequest';
import { IPatient } from '../store/types';
import { SearchPatient } from './types';

export const PatientsApi = {
  getPatients: async () => {
    const { data } = await makeRequest<IPatient[]>({
      url: baseurl + '/studies',
    });
    return data.map((patient) => ({ ...patient, uuid: uuidv4() }));
  },
  getByName: async (name: string): Promise<SearchPatient[]> => {
    const { data } = await makeRequest<SearchPatient[]>({
      url: baseurl + `/patients?name=${name}`,
    });
    return data;
  },

  getByPhone: async (phone: string): Promise<SearchPatient[]> => {
    const { data } = await makeRequest<SearchPatient[]>({
      url: baseurl + `/patients?phone=${phone}`,
    });
    return data;
  },

  getById: async (id: string): Promise<SearchPatient[]> => {
    const { data } = await makeRequest<SearchPatient[]>({
      url: baseurl + `/patients?patientID=${id}`,
    });
    return data;
  },
};
