import { FC, useState } from 'react';

import Search from 'antd/lib/input/Search';

interface PatientsSearchProps {
  onSearch: (value: string, selectType: string) => void;
  isLoading: boolean;
  setQr?: (isQr: boolean) => void;
  setJournal?: (isJournal: boolean) => void;
}

export enum SelectTypes {
  NAME = 'name',
  PHONE = 'phone',
  ID = 'id',
  QR = 'qr',
  JOURNAL = 'journal',
}

const PatientsSearch: FC<PatientsSearchProps> = ({ isLoading, onSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (value: string) => {
    const type = value.match(/^\d+$/) ? SelectTypes.PHONE : SelectTypes.NAME;
    onSearch(value, type);
  };

  return (
    <Search
      placeholder="Пошук пацієнта"
      onSearch={handleSearch}
      loading={isLoading}
      disabled={isLoading}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );
};

export default PatientsSearch;
