import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { Alert, Button, Collapse, Input, notification } from 'antd';

import styles from './offer.module.scss';
import useStore from '../../hooks/useStore';
import { dateFormat } from './constants';
import { LoadingStatus } from '../../store/types';
import { CalendarDate, Patient, Services } from './components';
import { NewOfferSearchData } from './types';

const { Panel } = Collapse;

interface CreateNewOfferProps {
  onCreated: () => void;
}

const CreateNewOffer: FC<CreateNewOfferProps> = ({ onCreated }) => {
  const { scheduleStore } = useStore();

  const [searchData, setSearchData] = useState<NewOfferSearchData>({
    date: moment().format(dateFormat),
    patient: {
      patientID: null,
      name: '',
      email: '',
      phone: '',
      birthDate: '',
    },
    services: [],
    notes: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (scheduleStore.offerLoadingStatus === LoadingStatus.SUCCESS) {
      notification.success({
        message: 'Запис додано',
      });
      scheduleStore.setOfferLoadingStatus(LoadingStatus.NEVER);
      onCreated();
    } else if (scheduleStore.offerLoadingStatus === LoadingStatus.ERROR) {
      notification.error({
        message: scheduleStore.offerError || 'Виникла помилка',
      });
      scheduleStore.setOfferLoadingStatus(LoadingStatus.NEVER);
    }
  }, [scheduleStore.offerLoadingStatus]);

  const handleCreate = () => {
    (async () => {
      if (!searchData.services?.length) {
        setError('Послуги не обрані');
      } else if (!searchData.date) {
        setError('Дата не обрана');
      } else {
        setError('');
        await scheduleStore.offer(searchData);
      }
    })();
  };

  return (
    <div>
      <Collapse
        defaultActiveKey={[
          'patient',
          'time',
          'services',
          'notes',
          'serviceType',
          'date',
        ]}
      >
        <Panel header="Обрати пацієнта" key="patient">
          <Patient searchData={searchData} setSearchData={setSearchData} />
        </Panel>
        <Panel header="Дата" key="date">
          <CalendarDate
            setDate={(date) => setSearchData((prev) => ({ ...prev, date }))}
          />
        </Panel>
        <Panel header="Обрати послуги" key="services">
          <Services
            date={searchData.date}
            value={searchData.services || []}
            removeService={(service) => {
              setSearchData((prev) => ({
                ...prev,
                services: (prev.services || []).filter(
                  (s: any) => s.serviceUUID !== service.serviceUUID
                ),
              }));
            }}
            addService={(service) =>
              setSearchData((prev) => ({
                ...prev,
                services: prev.services
                  ? [...prev.services, service]
                  : [service],
              }))
            }
          />
        </Panel>

        <Panel header="Примітка" key="notes">
          <Input
            placeholder="Примітка"
            onBlur={(e) =>
              setSearchData((prev) => ({ ...prev, notes: e.target.value }))
            }
          />
        </Panel>
      </Collapse>
      {!!error && (
        <Alert className={styles.errorMessage} message={error} type="error" />
      )}
      <Button
        style={{ margin: '20px 0' }}
        type="primary"
        loading={scheduleStore.offerLoadingStatus === LoadingStatus.LOADING}
        onClick={handleCreate}
      >
        Створити запис
      </Button>
    </div>
  );
};

export default observer(CreateNewOffer);
