import axios from './axios';
import { baseurl } from './baseurl';

export const FilesApi = {
  checkIsoFileStatus: async (studyUUID: string | number) => {
    const { data } = await axios.get(
      baseurl + `/studies/${studyUUID}/files/checkStudyImage`
    );

    return data;
  },
  buildIsoFile: async (studyUUID: string | number) => {
    const { data } = await axios.get(
      baseurl + `/studies/${studyUUID}/files/buildStudyImage`
    );

    return data;
  },
  getFileById: async (
    studyUUID: string | number,
    fileUUID: string | number
  ) => {
    const data = await axios.get(
      baseurl + `/studies/${studyUUID}/files/${fileUUID}`,
      {
        responseType: 'blob',
      }
    );

    const filename = data.headers['content-disposition']
      ?.replace('inline; filename=', '')
      ?.replace('attachment; filename=', '');
    const url = URL.createObjectURL(data.data);
    return { url, filename };
  },
};
