import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';

import styles from './DCInfo.module.scss';

import { DCInfoApi, IDCInfo } from '../../api/dcInfo';
import Phone from '../Phone';

interface DCInfoProps {
  centered?: boolean;
}

const DCInfo: FC<DCInfoProps> = ({ centered }) => {
  const [info, setInfo] = useState<IDCInfo | null>(null);
  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const [dcInfo, dcLogo] = await Promise.all([
          DCInfoApi.getInfo(),
          DCInfoApi.getLogo(),
        ]);
        setInfo(dcInfo);
        setLogo(dcLogo.url);
      } catch (e) {}
    })();
  }, []);

  const phones = info?.phones.length ? info.phones.split(', ') : [];

  return (
    <div
      className={classNames(styles.container, { [styles.centered]: centered })}
    >
      <div className={styles.meta}>
        {logo && <img src={logo} alt="logo" />}
        {info && <Title level={2}>{info?.welcomeText}</Title>}
        {info && !!phones?.length && (
          <div className={styles.phones}>
            {phones.map((phone) => (
              <Phone key={phone} phone={phone} />
            ))}
          </div>
        )}
      </div>
      {info?.manager && (
        <>
          <div className={styles.manager}>
            <Typography>
              <b>Ваш менеджер: </b>
              {info.manager.name}
            </Typography>
            <Phone phone={info.manager.phone} />
          </div>
        </>
      )}
    </div>
  );
};

export default DCInfo;
