export const getAuthToken = (): { token: string; expires: string } => {
  const tokenJson = localStorage.getItem('token');
  const expiresJson = localStorage.getItem('expires');

  let token,
    expires = '';

  if (tokenJson && expiresJson) {
    token = JSON.parse(tokenJson);
    expires = JSON.parse(expiresJson);
  }

  return {
    token,
    expires,
  };
};

export const isTokenExpired = () => {
  const { token, expires } = getAuthToken();

  return (
    !token || !expires || !Date.parse(expires) || new Date(expires) < new Date()
  );
};

export const saveAuthData = (token: string, expires: string, name: string) => {
  localStorage.setItem('token', JSON.stringify(token));
  localStorage.setItem('expires', JSON.stringify(expires));
  localStorage.setItem('name', JSON.stringify(name));
};

export const clearAuthData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expires');
  localStorage.removeItem('name');
};
