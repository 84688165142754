import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Radio } from 'antd';

import useStore from '../../../../hooks/useStore';
import { LoadingStatus } from '../../../../store/types';
import { Loader } from '../../../../components';

interface ServiceTypeProps {
  setServiceType: (studyRoom: string) => void;
  value: string;
}

const ServiceType: FC<ServiceTypeProps> = ({ setServiceType, value }) => {
  const { scheduleStore } = useStore();

  useEffect(() => {
    if (!scheduleStore.serviceTypes.length) {
      (async () => {
        await scheduleStore.getServiceTypes();
      })();
    }
  }, [scheduleStore.serviceTypes]);

  useEffect(() => {
    if (scheduleStore.serviceTypes.length) {
      if (
        !scheduleStore.serviceTypes.some((room) => room.serviceTypeUUID === value)
      ) {
        setServiceType(scheduleStore.serviceTypes[0].serviceTypeUUID);
      } else if (!value) {
        setServiceType(scheduleStore.serviceTypes[0].serviceTypeUUID);
      }
    }
  }, [scheduleStore.serviceTypes.length, value]);

  return (
    <div>
      {scheduleStore.getStudyRoomsLoadingStatus !== LoadingStatus.LOADING ? (
        <>
          {!!scheduleStore.serviceTypes.length && (
            <Radio.Group
              value={value || scheduleStore.serviceTypes[0].serviceTypeUUID}
              buttonStyle="solid"
              style={{ marginBottom: 20 }}
            >
              {scheduleStore.serviceTypes.map((room) => (
                <Radio.Button
                  key={room.serviceTypeUUID}
                  value={room.serviceTypeUUID}
                  onClick={() => {
                    setServiceType(room.serviceTypeUUID);
                  }}
                >
                  {room.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          )}
        </>
      ) : (
        <div style={{ height: 40, width: 50, position: 'relative' }}>
          <Loader />
        </div>
      )}
    </div>
  );
};
export default observer(ServiceType);
