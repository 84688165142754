import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './DownloadFilePage.module.scss';

import { baseurl } from '../../api/baseurl';
import { DCInfo, Loader } from '../../components';
import { Alert } from 'antd';

const DownloadFilePage: FC = () => {
  const location = useLocation();
  const [blob, setBlob] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [filename, setFilename] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (location.pathname && location.search) {
          const url = `${location.pathname}${location.search}`;
          const data = await fetch(baseurl + url);
          const blob = await data.clone().blob();
          if (blob.type === 'application/pdf') {
            const filenameMatch = data.headers
              .get('content-disposition')
              ?.replace('inline; filename=', '')
              .replace('.pdf', '');
            const filename = filenameMatch ? filenameMatch : 'Report';
            setFilename(filename);
            setBlob(URL.createObjectURL(blob));
            setLoading(false);
            return;
          }

          setError((await data.clone().json()).data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [location]);

  return (
    <>
      <DCInfo />
      <div className={styles.container}>
        {loading && <Loader />}
        {!!error && <Alert type="error" message={error} />}
        {blob && (
          <a download={filename} href={blob}>
            Завантажити
          </a>
        )}
      </div>
    </>
  );
};

export default DownloadFilePage;
