import { CSSProperties, FC, ReactElement } from 'react';
import { Button, Dropdown } from 'antd';

export interface INavButton {
  title: string;
  onClick: () => void;
  overlay?: ReactElement;
  icon?: ReactElement;
  disabled?: boolean;
  loading?: boolean;
  style?: CSSProperties;
}

const NavButton: FC<INavButton> = ({
  title,
  icon,
  onClick,
  style,
  loading,
  disabled,
  overlay,
}) => {
  return (
    <>
      {!!overlay ? (
        <Dropdown.Button
          trigger={['click']}
          onClick={onClick}
          overlay={overlay}
          style={style}
          type="primary"
          size="middle"
          icon={icon}
        >
          {title}
        </Dropdown.Button>
      ) : (
        <Button
          key={title}
          disabled={disabled}
          loading={loading}
          icon={icon}
          style={style}
          type="primary"
          size="middle"
          onClick={onClick}
        >
          {title}
        </Button>
      )}
    </>
  );
};

export default NavButton;
