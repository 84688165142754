import { types } from 'mobx-state-tree';

const File = types.model('File', {
  serverUrl: types.string,
  blobUrl: types.string,
  name: types.string,
});

const FilesStore = types
  .model('FilesStore', {
    cashFiles: types.array(File),
  })
  .actions((self) => {
    const clearFiles = () => {
      self.cashFiles.clear();
    };
    const addFile = (file: {
      serverUrl: string;
      blobUrl: string;
      name: string;
    }) => {
      self.cashFiles.push(file);
    };
    const getFile = (serverUrl: string) => {
      return self.cashFiles.find((file) => file.serverUrl === serverUrl);
    };
    return {
      clearFiles,
      addFile,
      getFile,
    };
  });

export default FilesStore;
