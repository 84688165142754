import { useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';

import useStore from '../../hooks/useStore';
import { IPatient } from '../../store/patient';

const useSearchPatients = () => {
  const { patientsStore } = useStore();

  const [searchValue, setSearchValue] = useState('');
  const [searchedPatients, setSearchedPatients] = useState<IPatient[]>([]);

  toJS(patientsStore.patients) // trigger to update state

  useEffect(() => {
    patientsStore.getPatients();
  }, []);

  const timeout = useRef<any>();

  useEffect(() => {
    timeout.current = setTimeout(() => {
      patientsStore.patients?.length && searchValue.trim()
        ? setSearchedPatients(
            patientsStore.patients.filter((patient) =>
              Object.entries(patient).some(([key, value]) => {
                if (key === 'patientPhone' || key === 'patientName') {
                  return value
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
                }
                return false;
              })
            ) || []
          )
        : setSearchedPatients(patientsStore.patients);
    }, 500);
  }, [searchValue, patientsStore.patients]);

  return {
    searchValue,
    setSearchValue,
    searchedPatients,
  };
};

export default useSearchPatients;
